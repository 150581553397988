import React, {useReducer, useEffect, useState} from 'react';
import {
    Link,
    useNavigate
} from 'react-router-dom';
import './Navigation.css';
import Logo1  from '../../assets/images/logo.svg';
import user_icon  from '../../assets/images/user-icon.png';
import {MenuContext} from '../sideMenu/SideMenuContext';
import { useSelector } from 'react-redux';
//import {userService} from '../../services/user.services';
import { useDispatch } from 'react-redux';
import SideMenuHorizontal from '../sideMenu/SideMenuHorizontal';
//import Moment from 'moment';

const SideMenuButton= (props) =>{
    return (
        <div  onClick={()=>props.onClick()} className={`side-menu-button${props.isActive ? ' side-menu-button-active':''}`}>
            <div className={`side-menu-button-bar ${props.isActive ? 'side-menu-button-bar-1-active':'side-menu-button-bar-1'}`}></div>
            <div className={`side-menu-button-bar ${props.isActive ? 'side-menu-button-bar-2-active':'side-menu-button-bar-2'}`}></div>
            <div className={`side-menu-button-bar ${props.isActive ? 'side-menu-button-bar-3-active':'side-menu-button-bar-3'}`}></div>
        </div>
    )
}

const NavigationBar = (props) => {

    let history = useNavigate();

    const user = useSelector(state => state.user);
    
    const dispatch = useDispatch();

    const [showUserMenu, setShowUserMenu] = useState(false)

    const toggleUserMenu = () => setShowUserMenu(!showUserMenu)

    const ref = React.createRef();

    function handleNavigation(toLink) {
        history(toLink);
    }

    const logoutUser = (e) => {
        //signOutUser(dispatch);
        //userService.logout();
        dispatch({type:"LOGOUT_USER"})
        e.preventDefault(); //userService.logout()
    }

    useEffect(()=>{

        const hideMenu =() => {
            setShowUserMenu(false);
        }

        document.body.addEventListener("click", hideMenu )

        return () => {
            document.body.removeEventListener("click", hideMenu);
        };

    },[])

    return (
        <MenuContext.Consumer>
            {({isOpen, setIsOpen}) => (<div className={"navigation-bar"}>
            <div>
                {props.showSideMenuButton && <SideMenuButton onClick={()=>setIsOpen(true)}  isActive={isOpen} />}
                <Link to="/" style={{height:'98%',paddingLeft:10, textDecoration:'none'}} className="app-logo" >
                    
                    <img src={Logo1} style={{height:36,fontFamily:'Dancing Script'}} alt="logo" />
                    {/*<img src={LogoText}  className="app-logo" alt="logo" />*/}
                    <div className={"app-title"} style={{fontWeight:'bold'}}>Oda<span style={{opacity:1.0, color:"#f7941e"}}>Now</span></div>
                    
                </Link>
            </div>
            <div style={{padding:10}} >

            <div className='side-menu-container-horizontal'>
            <SideMenuHorizontal/>
            </div>
            
            

            <div style={{position:'relative'}}>
               {/*  <div className="user-info-avartar" style={{ overflow:'hidden'}}>
                    <img width={24} src={user_icon} />                  
                </div> */}
                
            </div>
            <div className="user-info-wrapper">
                    {/* <div className="user-info-name">{`${user && user.name ? `Hi, ${user.name}` : "Guest"}`}</div>
 */}
                    {/* <div className="user-info-email" style={{fontSize:10}}>{"v1.3"} <span style={{fontStyle:'italic', fontWeight:'normal', color:'orange'}}>Beta</span></div>
                     */}
            </div>
           
            
            {/* {user ? <>
            <div style={{position:'relative'}}>
                <div onClick={(e)=>{e.stopPropagation(); toggleUserMenu()}} className="user-info-avartar">
                    {user.firstName?.charAt(0)}                    
                </div>
                <div className={'user-info-menu'} style={{display:`${showUserMenu? 'block': 'none'}`}}>
                        <ul style={{padding:0}}>                           
                            <li><a href={null} onClick={logoutUser}>Sign Out</a></li>
                        </ul>
                </div>
            </div>
            <div className="user-info-wrapper">
                    <div className="user-info-name">{`${user.firstName} ${user.lastName}`}</div>

                    <div className="user-info-email" style={{fontSize:12}}>{user.email}</div>
                    
            </div>
           
            </>:
            <>
                <button className={'navigation-bar-button-1'} onClick={()=>handleNavigation('/login')}>Login</button>
                <button className={'navigation-bar-button-2'} onClick={()=>handleNavigation('/signup')}>Sign Up</button>
            </>} */}
            </div>
            </div>)} 
        </MenuContext.Consumer>
    );
}

export default NavigationBar;