import React from 'react';
import { Link } from 'react-router-dom';
import LayoutWrapper from '../layout/LayoutWrapper'


const ContactUsPage = (props) => {

    return (<div style={{width:'100%'}}>
    <div className='banner-wrapper-01' style={{minHeight:400, background:'orange'}}>
   
   <Link className='button-01' to={'http://app.odanow.net'}>Let's Start</Link>
   </div>
   
   <div className='banner-wrapper-01' style={{fontWeight:'bold', fontSize:30}}>
   Contact Us
   </div>
        <div style={{width:'100%', padding:20, display:'flex', flexGrow:1, justifyContent:'center', alignItems:'center'}}> Email us via info@odanow.net</div>
    
    </div>)
}

export default LayoutWrapper(ContactUsPage, {showNavigationBar:true, showSideMenu:true});