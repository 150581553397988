import React from 'react';
import { Link } from 'react-router-dom';
import LayoutWrapper from '../layout/LayoutWrapper'


const BenefitsPage = (props) => {

    return (<div style={{width:'100%'}}>
    <div className='banner-wrapper-01' style={{minHeight:400, position:'relative', background:'orange'}}>
        <img style={{width:200, maxWidth:'50%', right:'5%', position:'absolute', borderRadius:5}} src={'/images/team-page-odanow.png'}/>
        <img style={{width:200, maxWidth:'50%', left:'5%',  position:'absolute', borderRadius:5}} src={'/images/inventory-page-odanow.png'}/>
        <img style={{width:300, maxWidth:'70%', position:'absolute', borderRadius:5}} src={'/images/orders-page-odanow.png'}/>
       
        <Link style={{color:'white', position:'absolute', bottom:'10%', boxShadow:'2px 2px 5px -2px rgba(20,20,20,0.7)', background:'orange'}} className='button-01' to={'http://app.odanow.net'}>Let's Start</Link>
   </div>
   
   <div className='banner-wrapper-01' style={{fontWeight:'bold', fontSize:30}}>
   About OdaNow
   </div>
        <div style={{display:'flex', justifyContent:'center',width:'100%'}}>
        <div style={{width:'100%', maxWidth:800, textAlign:'left', padding:20, }}> 
        An Order Management System (OMS) can be of great advantage to SMEs (Small and Medium Enterprises) in several ways:

<ol>
    <li>
    Increased Efficiency: An OMS automates and streamlines the order processing, reducing manual errors and increasing efficiency. This saves time and resources, freeing up valuable manpower to focus on other areas of the business.

    </li>
    <li>
    Improved Inventory Management: An OMS integrates with the inventory management system, providing real-time data on stock levels, reducing the risk of stock shortages, and enabling smarter purchasing decisions.

    </li>
    <li>
    Better Customer Experience: With real-time order tracking, customers can see the status of their orders, reducing the number of enquiries and improving the overall customer experience.

    </li>
    <li>
    Increased Sales: An OMS can automate upselling and cross-selling opportunities, helping SMEs to increase sales and revenue.

    </li>
    <li>
    Data Insights: An OMS generates valuable data and insights on customer behavior, sales trends, and inventory levels, enabling SMEs to make data-driven decisions and improve their operations.

    </li>
    <li>
    Scalability: An OMS can be scaled to meet the changing needs of an SME as it grows, providing a long-term solution for managing orders and improving operations.

    </li>
</ol>






        </div>
        </div>
        </div>

    )
}

export default LayoutWrapper(BenefitsPage, {showNavigationBar:true, showSideMenu:true});