import React, { useEffect, useRef, useState } from 'react'
import { useSprings, animated } from 'react-spring'
import { useDrag } from 'react-use-gesture'
import './SliderNav.css';
import styled, {keyframes} from 'styled-components'; 
//import {useImagesContext} from '../../../../contexts/ImagesContext';
import leftArrow from './images/arrow_left.svg'
import rightArrow from './images/arrow_right.svg'

// Should consider importing this from Lodash
const clamp = (number, lower, upper) => {
    const lowerClampedValue = Math.max(number, lower);

    const clampedValue = Math.min(lowerClampedValue, upper);

    return clampedValue
  }


const pointLeft = keyframes`  0%{ 
      transform: translateX(5px); 
  }  
  
  50%{ 
      transform: translateX(10px); 
  }  
  
  100%{ 
      transform: translateX(5px); 
  }
` ; 

const pointRight = keyframes`  0%{ 
      transform: translateX(-5px); 
  }  

  50%{ 
      transform: translateX(-10px); 
  }  

  100%{ 
      transform: translateX(-5px); 
}
` ; 

const NavigationArrow = styled.div`
    border: 1px solid #d3cfcf;
    padding: 10px;
    /* width: 90%; */
    /* height: 120px; */
    display: flex;
    background:white;
    aspect-ratio:1;
    border-radius: 50%;
    box-shadow: 2px 2px 10px -5px;
    animation: ${props => props.mode == 'left' ? pointLeft : pointRight} 1.5s infinite;  

`
const NavigationContainer = styled.div`
    height: 100%;
    width: 10%;
    min-width:80px;
    position:absolute;    
    background-color: none;
    ${props => props.mode.toLowerCase()==='left' ? 'left:0px;'  : 'right:0px;'};        
    top:0px;
    display:flex;
    align-items:center;
    justify-content: ${props => props.mode && props.mode.toLowerCase()==='left' ? 'flex-start'  : 'flex-end'};   

    &:hover{
        background-color: rgb(2, 25, 41, 0.3);
        background: linear-gradient(
            ${props => props.mode.toLowerCase()==='left' ? '-90deg'  : '90deg'}
            , rgba(250,250,250,0) 0%, rgb(121 121 121 / 43%) 80%, rgb(76 76 76 / 58%) 100%);            
    }
`;

const SliderNav = (props) =>{

  let storedPosition = 0;//window.localStorage.getItem("navigator_position_index");

  let initialPosition = storedPosition && parseInt(storedPosition) < props.children.length ? parseInt(storedPosition) :0;

  //const { images } = useImagesContext();
  const index = useRef(initialPosition);
  const [pageIndex, setPageIndex] = useState(initialPosition +1);
  const [springProps, set] = useSprings(props.children.length, (i) => ({
    x: (i-initialPosition) * window.innerWidth,    
    scale: 1,
    display: 'block'
  }))

  const getNextCard = () =>
    {
        if(index.current + 1 == props.children.length) return;

        window.localStorage.setItem("navigator_position_index", (index.current +1));

        index.current = index.current+1;
        
        setPageIndex(index.current + 1);
        set((i) => {            
            const x = (i - index.current) * window.innerWidth;
            const scale = 1;
            return { x, scale, display: 'block' }
        })
    }

    const getPreviousCard = () =>
    {
        if(index.current === 0) return;

        window.localStorage.setItem("navigator_position_index", (index.current-1));

        index.current = index.current-1;
        
        setPageIndex(index.current + 1);
        set((i) => {            
            const x = (i - index.current) * window.innerWidth;
            const scale = 1;
            return { x, scale, display: 'block' }
        })
    }

    useEffect(()=>{
        const interval = setInterval(()=>{
            /*if(index.current >= props.children.length -1)
            {
                index.current = 0
            }
            else
            {
               
            }*/

            
            index.current = index.current + 1 >= props.children.length ? 0 : index.current + 1;
            setPageIndex(index.current + 1);
            console.log("POS", {i:index.current, pageIndex})
            set((i) => {            
                
               
                const x = (i - index.current) * window.innerWidth;
                const scale = 1;
                return { x, scale, display: 'block' }
            })

           

            
            
        },5000);

        return () => clearInterval(interval);
    },[])
  const bind = useDrag(({ active, movement: [mx], direction: [xDir], distance, cancel }) => {
    if (active && distance > window.innerWidth / 2)
    {  
        cancel((index.current = clamp(index.current + (xDir > 0 ? -1 : 1), 0, props.children.length - 1)))
        setPageIndex(index.current + 1);
    }
    set((i) => {
      /* Prevent rerendering of hidden views  */    
      //if (i < index.current - 1 || i > index.current + 1) return { display: 'none' }   

      const x = (i - index.current) * window.innerWidth + (active ? mx : 0)
      const scale = active ? 1 - distance / window.innerWidth / 2 : 1
      return { x, scale, display: 'block' }
    })
  })
  return <><div className={'cards-container'} style={{...props.style}}>
     
          {springProps.map(({ x, display, scale }, i) => (
            <animated.div {...bind()} key={i} style={{ display, left:x, scale }}>
            {/*<animated.div style={{display:'flex', flexDirection:'column', alignContent:'center', alignItems:'center', scale,backgroundImage: `url(${pages[i]})` }} >*/}
            <animated.div style={{display:'flex', flexDirection:'column', alignContent:'center', alignItems:'center', scale }} >
            
                <div style={{display:'flex', flexDirection:'row', alignItems:'center', width:props.style?.width, height:'100%'}}>{props.children[i]}</div>{/*`${JSON.stringify(scale)}--`*/}
            </animated.div>
              
            </animated.div>
          ))}</div>
          <div style={{position:'absolute', bottom:10, display:'flex', flexDirection:'column', alignItems:'center', width:'100%'}}>
            <div style={{display:'inline-block', padding:'2px 10px', borderRadius:15, fontSize:12, color:'#FFFFFF', background:'rgba(10,10,10,0.6)'}}>{`${pageIndex} of ${props.children.length}`}</div>
          </div>

          <div style={{position:'absolute', bottom:40, display:'flex', flexDirection:'row', justifyContent:'center', width:'100%'}}>
            {props.children.map((i, index) => <div style={{display:'inline-block', borderRadius:15, height:10, width: index == pageIndex -1 ? 20 : 10, margin:5, fontSize:16, boxShadow:'1px 1px 2px rgba(20,20,20,0.4)', color:'#FFFFFF', background: index == pageIndex -1 ? 'orange':'rgba(10,10,10,0.6)'}}></div>)}
          </div>
        
          {pageIndex > 1 && <NavigationContainer onClick={()=>getPreviousCard()} mode='left' className={"hand-pointer"}>
                    <NavigationArrow  mode='left' style={{/* width:'100%', height:120, */ backgroundColor:'none', display:'flex' }} ><img src={leftArrow} style={{width:20}}/></NavigationArrow>
          </NavigationContainer>}
          {pageIndex <  props.children.length && <NavigationContainer onClick={()=>getNextCard()} mode='right' className={"hand-pointer"}>
                    <NavigationArrow  mode='right' style={{/* width:'100%', height:120, */ backgroundColor:'none',  display:'flex'}}><img src={rightArrow} style={{width:20}}/></NavigationArrow>
          </NavigationContainer>}
      </>
}

export default SliderNav;