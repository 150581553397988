import React from 'react';
import LayoutWrapper from '../layout/LayoutWrapper';
import './pagestyles.css'


const ProductsPage = (props) => {

    return (
        <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', width:'100%'}}>
            <div style={{maxWidth:'800px', width:'100%'}}>
            <h1>Products</h1>
            <div style={{padding:10}}>
                <input className='search-field' placeholder='Search...' /> 
            </div>
           {/*  <div style={{display:'inline-grid', width:'100%', gridTemplateColumns:'repeat(auto-fill, minmax(300px, 1fr))'}}>
                {products.map(p =><div style={{display:'flex', alignItems:'center', justifyContent:'center'}}> <ProductCard title={p.title} description={p.description} price={p.price} image={p.coverImageUrl} /></div>)}
            </div> */}
            </div>
            
        </div>
    )
}

export default LayoutWrapper(ProductsPage, {showNavigationBar:true, showSideMenu:true});