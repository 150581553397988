import React from 'react';
import NavigationBar from './navigation/NavigationBar';
import './LayoutWrapper.css'
import SideMenu from './sideMenu/SideMenu';
import {SideMenuContext} from './sideMenu/SideMenuContext';
import NotificationContainer from '../notification/NotificationContainer';
import { Link } from 'react-router-dom';
// Navigation Layout is a HOC for pages
// that will require navigation views to be present.
const LayoutWrapper = (PageComponent, options={}) => (props) => {
        
    return (<SideMenuContext>
            {/*<NotificationsWrapper>*/}
            <div style={{minHeight:'100vh', display:'flex', flexDirection:'column'}}>
            {options.showNavigationBar && <NavigationBar showSideMenuButton={options.showSideMenu}/>}
             {/* Notification Container */}
             <NotificationContainer />

            <div background={options.backgroundImage} className={`centered-view ${options.showSideMenu? 'offset-centered-view':''}`} style={{
                backgroundColor:options.backgroundColor,
                backgroundImage:`url(${options.backgroundImage})`,
                backgroundRepeat:'no-repeat',
                backgroundSize:'cover',
                flexGrow:1,
                minHeight:'auto',
                flexDirection:'column',
                alignItems:`${options.alignCenter? 'center':'flex-start'}`,
                justifyContent:`${options.alignCenter? 'center': 'start'}`
            }}>              
                
                
                {/*Page Component*/}
                <PageComponent {...props}/>
               
                {/*Side Menu*/}
                {options.showSideMenu === true && <SideMenu/>}
            </div>
            <div style={{background:'#ef5a29', color:'white', width:'100%', flexDirection:'column', alignItems:'center', padding:10, minHeight:50, display:'flex'}}>
                <div style={{display:'flex', padding:10, justifyContent:'space-evenly', width:'100%', fontSize:10, lineHeight:1.5}}>
                    <div style={{textAlign:'left', display:'flex', flexDirection:'column'}}> {[{label:"Contact Us", link:'/contact-us'}, {label:"Our Story", link:'/our-story'}].map(footerItem => <Link style={{textDecoration:'none', color:'inherit'}} to={footerItem.link}>{footerItem.label}</Link>)}</div>
                    <div style={{textAlign:'left', display:'flex', flexDirection:'column'}}> {[{label:"Privacy Policy", link:'/privacy-policy'}, {label:"Terms and Conditions", link:'/terms-and-conditions'}].map(footerItem => <Link style={{textDecoration:'none', color:'inherit'}} to={footerItem.link}>{footerItem.label}</Link>)}</div>
                </div>

                
              {/*   <div style={{textAlign:'center', display:'flex', width:200, padding:10, justifyContent:'space-around', fontSize:11}}>
                    <img width={24} src={'/social/facebook.svg'}/>
                    <img width={24} src={'/social/instagram.svg'}/>
                    <img width={24} src={'/social/whatsapp.svg'}/>
                </div> */}
                <div style={{textAlign:'center', fontSize:12, padding:10}}>info@odanow.net</div>
                <div style={{textAlign:'center', fontSize:10}}>OdaNow {(new Date).getFullYear()}</div>
            </div>
            </div>
            {/*</NotificationsWrapper>*/}
            
        </SideMenuContext>)
}

export default LayoutWrapper;


