import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NotificationManager from './NotificationManager';
import Notifications from './Notifications';
import './Notification.css'

class NotificationContainer extends Component {
  static propTypes = {
    enterTimeout: PropTypes.number,
    leaveTimeout: PropTypes.number
  };

  static defaultProps = {
    enterTimeout: 400,
    leaveTimeout: 400
  };

  state = {
    notifications: []
  };

  componentWillMount = () => {
    NotificationManager.addChangeListener(this.handleStoreChange);
  };

  componentWillUnmount = () => {
    NotificationManager.removeChangeListener(this.handleStoreChange);
  };

  handleStoreChange = notifications => {
    this.setState({
      notifications
    });
  };

  handleRequestHide = notification => {
    NotificationManager.remove(notification);
  };

  render() {
    const { notifications } = this.state;
    const { enterTimeout, leaveTimeout } = this.props;
    return (
      <Notifications
        enterTimeout={enterTimeout}
        leaveTimeout={leaveTimeout}
        notifications={notifications}
        onRequestHide={this.handleRequestHide}
      />
    );
  }
}

export default NotificationContainer;
/*import React from 'react';

import {NotificationContext} from './NotificationsManager';
import './Notification.css';

const NotificationContainer = (props) => {
    return(
        <NotificationContext.Consumer>
        {({notifications, pushNotification, removeNotification}) => (
        <>
         <div className={`${'offset-centered-view'}`} style={{
                    //position:'fixed',
                    top:100,
                    justifyContent:`${'center'}`,
                    padding:0,
                    position:'fixed',
                    width:'100%',
                    zIndex:100
                }}>
                    <div className={'notification-container'}>
                    {notifications.map(n => {
                        return <div key={n.id} className={`${n.className} notification-wrapper`}>
                            <div className={'notification-title'}>{n.title}</div>
                            {n.message && <div className={'notification-message'}>{n.message}</div>}
                            <div onClick={()=>removeNotification(n.id)} className={'notification-close-button'}>
                            </div>
                        </div>
                    })}
                    </div>
        </div>
        </>)}
        </NotificationContext.Consumer>)
    
}

export default NotificationContainer;*/