import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import { Route, Routes,BrowserRouter} from 'react-router-dom';  
import HomePage from './pages/HomePage';
import ProductsPage from './pages/ProductsPage';
import OurStoryPage from './pages/OurStoryPage';
import ContactUsPage from './pages/ContactUsPage';
import BenefitsPage from './pages/BenefitsPage';
import PrivacyPolicyView from './pages/PrivacyPolicyView';
import TermsAndConditionsView from './pages/TermsAndConditionsView';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TermsAndConditionsPage from './pages/TermsAndConditionsPage';

function App() {
  return (
    <div className="App">
      <Routes>  
        <Route path='/products' element={<ProductsPage/>} />
        <Route path='/our-story' element={<OurStoryPage/>} />
        <Route path='/contact-us' element={<ContactUsPage/>} />
        <Route path='/why-odanow' element={<BenefitsPage/>} />
        <Route path='/privacy-policy-view' element={<PrivacyPolicyView/>} />
        <Route path='/terms-and-conditions-view' element={<TermsAndConditionsView/>} />
        <Route path='/privacy-policy' element={<PrivacyPolicyPage/>} />
        <Route path='/terms-and-conditions' element={<TermsAndConditionsPage/>} />
        <Route path='/' element={<HomePage/>} />
      </Routes>      
    </div>
  );
}

export default App;
