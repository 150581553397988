import React from 'react';
import { Link } from 'react-router-dom';
import LayoutWrapper from '../layout/LayoutWrapper'


const OurStoryPage = (props) => {

    return (<div style={{width:'100%'}}>
 <div className='banner-wrapper-01' style={{minHeight:400, background:'orange'}}>

<Link className='button-01' to={'http://app.odanow.net'}>Let's Start</Link>
</div>

<div className='banner-wrapper-01' style={{fontWeight:'bold', fontSize:30}}>
About OdaNow
</div>

<div style={{display:'flex', justifyContent:'center',width:'100%'}}>
            
<div style={{width:'100%', maxWidth:800, textAlign:'left', padding:20, }}> 
        OdaNow is designed to be user-friendly and scalable to meet the needs of businesses of all sizes. Whether you're a small startup or a large enterprise, our Order Management System can help you streamline your operations and improve the customer experience.

Start experiencing the benefits of a seamless order management process today. Contact us to schedule a demo and see how our platform can revolutionize your business.


Our Order Management Automation solution. Our user-friendly interface allows you to manage your orders, inventory, and shipping with ease. Plus, our 24/7 support team is here to help you every step of the way.

Don't miss out on the benefits of automating your order management. Contact us today to schedule a demo and see how our platform can transform your business.

        </div>
        </div>

    </div>
        

    )
}

export default LayoutWrapper(OurStoryPage, {showNavigationBar:true, showSideMenu:true});