import React from 'react';
import { Link } from 'react-router-dom';
import SlideInAnimation from '../components/SlideInAnimation/SlideInAnimation';
import SliderNav from '../components/SliderNav/SliderNav';
import LayoutWrapper from '../layout/LayoutWrapper'
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
//import {useState, useEffect} from 'react';

export const useMobileDetect = () => {

const [width, setWidth] = useState(window.innerWidth);

function handleWindowSizeChange() {
    setWidth(window.innerWidth);
}

useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
}, []);

const isMobile = width <= 768;

return {isMobile};
}

function ScrollToAnchor() {
    const location = useLocation();
    const lastHash = useRef('');

    // listen to location change using useEffect with location as dependency
    // https://jasonwatmore.com/react-router-v6-listen-to-location-route-change-without-history-listen
    useEffect(() => {
        if (location.hash) {
            lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
        }

        if (lastHash.current && document.getElementById(lastHash.current)) {
            setTimeout(() => {
                document
                    .getElementById(lastHash.current)
                    ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                lastHash.current = '';
            }, 100);
        }
    }, [location]);

    return null;
}


//export default ScrollToAnchor;

const h1Style = {
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'left',
    padding: '10px 2px'
}

const h2Style = {
    fontSize: 13,
    fontWeight: 'bold',
    textAlign: 'left',
    padding: '10px 2px'
}

const p1Style = {
    fontSize: 10,
    fontWeight: 'normal',
    textAlign: 'left',
    color: '#585858'
}

const FeatureBox = (props) => {

    return (
        <div style={{ display: 'flex', alignItems: 'center', maxWidth: 300, padding: 10 }}>
            <div>
                <div style={{
                    width: 32, height: 32, 
                    //backgroundColor: '#ffeac3', 
                    borderRadius: '5px', margin: 10, backgroundImage: props.icon ? `url('images/${props.icon}')` : null,
                    backgroundPosition: 'top', backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundRepeat: 'no-repeat'
                }}>
                </div>
            </div>


            <div style={p1Style}>{props.children}</div>
        </div>
    )
}

const FeatureBoxView = (props) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', maxWidth: 300, minWidth: 250, padding: 10, margin: 10, boxShadow: '2px 2px 5px rgba(100,100,100,0.1)', border: '1px dotted orange' }}>
            <div>
                <div style={{
                    width: 32, height: 32, 
                    //backgroundColor: '#ececec', 
                    margin: 10, backgroundImage: props.icon ? `url('images/${props.icon}')` : null,
                    backgroundPosition: 'top', backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundRepeat: 'no-repeat'
                }}>
                </div>
            </div>


            <div style={p1Style}><div style={h2Style}>{props.title}</div>{props.children}</div>
        </div>
    )
}

const PricingViewItem = props => 
{
    return (
        <div style={{display: 'flex', alignItems:'center', width:'100%', marginTop:10}}>
            <span style={{ ...h1Style, textAlign: 'left', width:30, background: "url('images/check-icon.svg')",
                backgroundPositionX:0, backgroundRepeat: 'no-repeat', backgroundSize:'60%'
            }}></span>
            <span style={{ ...p1Style, textAlign: 'left', fontSize:12 }}>{props.children}</span>
        </div>   
    )
}

const pricing_plans = [
    {
        name:'Free',
        monthlyCost: 0,
        features:[            
            "Up to 1000 monthly orders",
            "Up to 200 inventory items",
            "Manage up to 2 team members",
            "Basic reporting",
            "Order tracking"
        ]
    },
    {
        name:'Basic',
        monthlyCost: 6000,
        features:[
            "Custom store domain",
            "Up to 10000 monthly orders",
            "Up to 500 inventory items",
            "Manage up to 10 team members",
            "Basic reporting",
            "Order tracking"
        ]
    },
    {
        name:'Premium',
        monthlyCost: null,
        features:[
            "Multiple store domains",
            "Unlimited monthly orders",
            "Unlimited inventory items",
            "Unlimited team members",
            "Advanced reporting",
            "Order tracking"
        ]
    }
]
const PricingBoxView = (props) => {

    const directToMailTo = (e) => {
        window.location.href = 'mailto:info@odanow.net';
        e.preventDefault();
    }
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems:'flex-start', margin:10, borderRadius:10, padding:10, border:'1px solid #CECECE', width: 300 }}>
        <div style={{borderBottom:'1px solid #CECECE', display: 'flex', justifyContent:'flex-start', flexDirection: 'column',width:'100%'}}>
            <span style={{ ...h1Style, textAlign: 'left', fontSize:16 }}>{props.name}</span>
            <span style={{ ...h1Style, textAlign: 'left' }}>NGN {props.monthlyCost ?? "--"}<span style={{ ...p1Style, textAlign: 'left' }}>/ month</span></span>
        </div>   
        <div style={{display: 'flex', flexDirection: 'column',width:'100%', padding:10}}>
            {props.features.map(f => <PricingViewItem>{f}</PricingViewItem>)}                  
        </div>   
        <div style={{ padding: 10, width:'100%' }}>
          {/*   <button style={{ padding: '10px 25px', color: 'white', background: '#ef5a29', border: 0, borderRadius: 5, width:'100%', }}>{props.monthlyCost ? 'Get Started' : 'Contact Us'}</button> */}
            <Link 
            onClick={!(props.monthlyCost  || props.monthlyCost == 0) && directToMailTo}

            style={{ padding: '10px 25px', color: 'white', display:'block', background: '#ef5a29', border: 0, borderRadius: 5, width:'100%',  textDecoration:'none', fontSize:12 }} 
            to={props.monthlyCost || props.monthlyCost == 0 ? 'https://app.odanow.net': '#'}>
                {props.monthlyCost  || props.monthlyCost == 0 ? 'Get Started' : 'Contact Us' }
            </Link>
                        
        </div>                 
    </div>
    )
}

const HomePage = (props) => {

    const {isMobile} = useMobileDetect();

    return (
        <div style={{ width: "100%" }}>
            <ScrollToAnchor />
            <div id={"home"} style={{ display: 'flex', position: 'relative', flexDirection: isMobile ? 'column' : 'row', width: '100%', minHeight: 300, padding: 0, alignItems: 'center', justifyContent: 'center' }}>

                <div style={{ display: 'flex', flexDirection: 'column', maxWidth: 300 }}>
                    <span style={h1Style}>Bridging the gap between Companies & Customers</span>
                    <span style={p1Style}>Our platform is designed to streamline and simplify the ordering process for businesses of all sizes.</span>
                    <div style={{ padding: 20 }}>
                        <Link style={{ padding: '10px 25px', background: '#ef5a29', color: 'white', border: 0, borderRadius: 20, textDecoration:'none', fontSize:12 }} to={'https://app.odanow.net'}>Get Started</Link>
                        
                    </div>
                </div>
                <div style={{ width: 300, height: 300, background: "url('images/banner-image-01.png')", backgroundPosition: 'top', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>

                </div>

            </div>
            <div style={{ display: 'flex', position: 'relative', width: '100%', minHeight: 100, padding: 0, alignItems: 'center', justifyContent: 'center' }}>

                <div style={{ display: 'flex', flexDirection: 'column', maxWidth: isMobile ? '80%':400, padding:10 }}>
                    <span style={h1Style}><span style={{ color: '#ef5a29' }}>10x</span> your  Customer Engagements</span>
                    <span style={p1Style}>Welcome to the world of order management automation! Whether you're a small startup or a large corporation, our platform offers the tools you need to manage your orders efficiently and effectively.</span>
                </div>

            </div>
            <div style={{
                display: 'flex',
                position: 'relative',
                width: '100%',
                flexDirection: isMobile ? 'column' : 'row',
                //background:'none', 
                //backgroundImage: "url('images/banner-image-02.png')",
                backgroundRepeat: 'no-repeat',
                backgroundPositionX: '0%',
                backgroundSize: 'contain',
                minHeight: 300,
                padding: 0,
                alignItems: 'center',
                justifyContent:'space-between'
            }}>
            {/*<div style={{ width: isMobile ? '100%' : 300, height: 300, background: "url('images/banner-image-02.png')", 
            backgroundPositionX:'-10px', backgroundPositionY: 'top', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>

        </div>*/}
        <div style={{ display:'flex', justifyContent:'flex-start'}}>
        <img style={{width:isMobile ? '90%' : 400,}} src={'images/banner-image-02.png'}/>
        </div>
        

                <div style={{ display: 'flex', flexDirection: 'column', maxWidth: 500, padding: 20 }}>

                    <FeatureBox icon={"activity-icon.svg"}>
                        Our platform provides real-time updates on the status of your orders, so you never have to worry about missing a step.
                    </FeatureBox>
                    <FeatureBox icon={"pie-chart-icon.svg"}>
                        With our advanced technology, you can easily manage your entire order lifecycle, from receiving orders to shipping them out.
                    </FeatureBox>
                    <FeatureBox icon={"integration-icon.svg"}>
                        We also integrate with a variety of shipping carriers and payment providers, so you can get your orders out the door as quickly and smoothly as possible
                    </FeatureBox>
                </div>

            </div>
            <div id="features" style={{ display: 'flex', position: 'relative', width: '100%', minHeight: 50, paddingTop: 20, alignItems: 'center', justifyContent: 'center' }}>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: 500 }}>
                    <span style={{ ...h1Style, textAlign: 'center' }}>Deliver excellence for your customers with <span style={{ color: '#333333' }}>Oda</span><span style={{ color: '#ef5a29' }}>Now</span></span>
                    <span style={p1Style}>Key Features</span>
                </div>

            </div>
            <div style={{
                display: 'flex',
                position: 'relative',
                width: '100%',
                //background:'none', 
                //backgroundImage: "url('images/banner-image-02.png')", 
                backgroundRepeat: 'no-repeat',
                backgroundPositionX: '0%',
                backgroundSize: 'contain',
                minHeight: 200,
                padding: 10,
                alignItems: 'center',
                justifyContent: 'center'
            }}>


                <div style={{ display: 'inline-grid', gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))", flexDirection: 'column', width: '80%', padding: 10 }}>
                    <FeatureBoxView title={"Collaborate with ease"} icon={"collaborate-icon.svg"}>
                        Our system allows you to share order information and status updates with team members in real-time, improving communication and reducing the risk of errors.
                    </FeatureBoxView>
                    <FeatureBoxView title={"Track orders"}  icon={"order-tracking-icon.svg"}>
                        You and your customers can keep tabs on your orders from start to finish with real-time updates and detailed tracking information.
                    </FeatureBoxView>
                    <FeatureBoxView title={"Manage your customers"}  icon={"manage-customers-icon.svg"}>
                        Organize customer information, including contact details and order history, all in one place.
                    </FeatureBoxView>
                    <FeatureBoxView title={"Streamline invoicing"}  icon={"invoicing-icon.svg"}>
                    Easily generate and manage invoices for your orders, saving time and reducing the risk of errors.
                    </FeatureBoxView>
                </div>

            </div>

            <div style={{ display: 'flex', position: 'relative', flexDirection: isMobile ? 'column' : 'row', width: '100%', minHeight: 300, padding: 0, alignItems: 'center', justifyContent: 'center' }}>

                <div style={{ display: 'flex', flexDirection: 'column', maxWidth: 300, padding:'15px 0px' }}>
                    <span style={h1Style}>Better Customer Experience</span>
                    <span style={p1Style}>With real-time order tracking, customers can see the status of their orders, reducing the number of enquiries and improving the overall customer experience.</span>
                </div>
                <div style={{ width: 300, height: 300, background: "url('images/banner-image-03.png')", backgroundPosition: 'top', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>

                </div>

            </div>

            <div style={{ display: 'flex', position: 'relative', flexDirection: isMobile ? 'column-reverse' : 'row', width: '100%', minHeight: 300, padding: 0, alignItems: 'center', justifyContent: 'center' }}>

                <div style={{ width: 300, height: 300, background: "url('images/banner-image-04.png')", backgroundPosition: 'top', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>

                </div>
                <div style={{ display: 'flex', flexDirection: 'column', maxWidth: 300, padding:'15px 0px' }}>
                    <span style={h1Style}>Increased Sales</span>
                    <span style={p1Style}>An OMS can automate upselling and cross-selling opportunities, helping SMEs to increase sales and revenue. Reducing manual errors and increasing efficiency. This saves time and resources, freeing up valuable manpower to focus on other areas of the business.</span>
                </div>


            </div>

            <div style={{ display: 'flex', position: 'relative', flexDirection: isMobile ? 'column' : 'row', width: '100%', minHeight: 300, padding: 0, alignItems: 'center', justifyContent: 'center' }}>


                <div style={{ display: 'flex', flexDirection: 'column', maxWidth: 300, padding:'15px 0px' }}>
                    <span style={h1Style}>Data Insights</span>
                    <span style={p1Style}>Generates valuable data and insights on customer behavior, sales trends, and inventory levels, enabling SMEs to make data-driven decisions and improve their operations.</span>
                </div>
                <div style={{ width: 300, height: 300, background: "url('images/banner-image-05.png')", backgroundPosition: 'top', backgroundSize: '100%', backgroundRepeat: 'no-repeat' }}>
                </div>

            </div>
            <div style={{ display: 'flex', position: 'relative', width: '100%', padding: 0, justifyContent: 'center' }}>

                <SliderNav style={{ padding: 0, width: '100%', minHeight: 350 }}>
                    <div className='banner-wrapper-01' style={{ minHeight: 400, width: '100%', background: "url('images/happy-african-american-man-holding-shopping-bags-yellow-background-holidays-concept.jpg')", backgroundPosition: 'top', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', }}>

                        <Link className='button-01' to={'https://app.odanow.net'}>Let's Start</Link>
                    </div>
                    <div className='banner-wrapper-01' style={{ minHeight: 400, width: '100%', background: "url('images/charming-joyful-caring-young-african-american-family-man-woman-siblings-smiling-broadly-show-heart-gestures-grinning-express-love-empathy-positivity-two-loyal-friends-cherish-friendship.jpg')", backgroundPosition: 'top', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', }}>

                        <Link className='button-01' to={'https://app.odanow.net'}>Let's Start</Link>
                    </div>
                </SliderNav>
            </div>

            {/* <div style={{ display: 'flex', position: 'relative', width: '100%', minHeight: 50, paddingTop: 20, alignItems: 'center', justifyContent: 'center' }}>

            <div id='pricing-plans' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: 500 }}>
                <span style={{ ...h1Style, textAlign: 'center' }}>Genuine experiences from our clients.</span>
            </div>

            </div>
            <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', position: 'relative', width: '100%', minHeight: 50, paddingTop: 20, alignItems: 'center', justifyContent: 'center' }}>

            <PricingBoxView></PricingBoxView>
            <PricingBoxView></PricingBoxView>

            </div>
 */}
            <div style={{ display: 'flex', position: 'relative', width: '100%', minHeight: 50, paddingTop: 20, alignItems: 'center', justifyContent: 'center' }}>

                <div id='pricing-plans' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: 500 }}>
                    <span style={{ ...h1Style, textAlign: 'center' }}>Get started with our flexible plans.</span>
                </div>

            </div>
            <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', position: 'relative', width: '100%', minHeight: 50, paddingTop: 20, alignItems: 'center', justifyContent: 'center' }}>

            {pricing_plans.map(pp => <PricingBoxView {...pp}></PricingBoxView>)}
              

            </div>

            <div style={{ display: 'flex', position: 'relative', width: '100%', minHeight: 20, padding: 0, alignItems: 'center', background: '#ef5a29', justifyContent: 'center' }}>




            </div>


            {/*<div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <div className='info-card-short-text'>
                    Welcome to the world of order management automation! Our platform is designed to streamline and simplify the ordering process for businesses of all sizes. Whether you're a small startup or a large corporation, our platform offers the tools you need to manage your orders efficiently and effectively.

                </div>
                <div style={{ maxWidth: 600, width: '100%', padding: 20, color: '#554433' }}>
                    With our advanced technology, you can easily manage your entire order lifecycle, from receiving orders to shipping them out. Our platform provides real-time updates on the status of your orders, so you never have to worry about missing a step. We also integrate with a variety of shipping carriers and payment providers, so you can get your orders out the door as quickly and smoothly as possible.

                </div>
            </div>
            <div className='banner-wrapper-01' style={{ fontWeight: 'bold', fontSize: 30 }}>
                Key Features
    </div>*/}
            {/*<div style={{ display: 'flex', position: 'relative', paddingBottom: 50, justifyContent: 'center' }}>
                <SliderNav style={{ maxWidth: 800, padding: 20, width: '100%' }}>
                    <div className='info-card-wrapper' style={{ display: 'flex', }}>
                        <div className='info-card-numbering' style={{ background: 'orange', width: 200, minHeight: 200 }}>1</div>
                        <div style={{ flexGrow: 1, background: '#EEEEEE', textAlign: 'left', padding: 10, }}>
                            <div style={{ fontSize: 20, padding: '20px 2px', fontWeight: 'bold' }}>Collaborate with ease</div>
                            Our system allows you to share order information and status updates with team members in real-time, improving communication and reducing the risk of errors.

                        </div>
                    </div>
                    <div className='info-card-wrapper' style={{ display: 'flex', }}>
                        <div className='info-card-numbering' style={{ background: 'orange', width: 200, minHeight: 200 }}>2</div>
                        <div style={{ flexGrow: 1, background: '#EEEEEE', textAlign: 'left', padding: 10, }}>

                            <div style={{ fontSize: 20, padding: '20px 2px', fontWeight: 'bold' }}>Track orders</div>
                            You and your customers can keep tabs on your orders from start to finish with real-time updates and detailed tracking information.

                        </div>
                    </div>
                    <div className='info-card-wrapper' style={{ display: 'flex', }}>
                        <div className='info-card-numbering' style={{ background: 'orange', width: 200, minHeight: 200 }}>3</div>
                        <div style={{ flexGrow: 1, background: '#EEEEEE', textAlign: 'left', padding: 10, }}>
                            <div style={{ fontSize: 20, padding: '20px 2px', fontWeight: 'bold' }}>Manage your customers</div>
                            Organize customer information, including contact details and order history, all in one place.

                        </div>
                    </div>
                    <div className='info-card-wrapper' style={{ display: 'flex', }}>
                        <div className='info-card-numbering' style={{ background: 'orange', width: 200, minHeight: 200 }}>4</div>
                        <div style={{ flexGrow: 1, background: '#EEEEEE', textAlign: 'left', padding: 10, }}>

                            <div style={{ fontSize: 20, padding: '20px 2px', fontWeight: 'bold' }}>Streamline invoicing</div>
                            Easily generate and manage invoices for your orders, saving time and reducing the risk of errors.

                        </div>

                    </div>
                    <div className='info-card-wrapper' style={{ display: 'flex', }}>
                        <div className='info-card-numbering' style={{ background: 'orange', width: 200, minHeight: 200 }}>5</div>
                        <div style={{ flexGrow: 1, background: '#EEEEEE', textAlign: 'left', padding: 10, }}>
                            <div style={{ fontSize: 20, padding: '20px 2px', fontWeight: 'bold' }}>Stay on top of notifications</div>
                            Receive real-time notifications for important updates, such as order confirmations and shipping updates, ensuring you never miss a beat.

                        </div>
                    </div>
                </SliderNav>
    </div>*/}
            <div style={{
                display: 'flex',
                position: 'relative',
                width: '100%',
                background: '#ef5a29',
                //backgroundImage: "url('images/footer-banner-image.png')",
                backgroundRepeat: 'no-repeat',
                backgroundPositionX: '100%',
                backgroundSize: 'contain',
                minHeight: 300,
                //padding: 10,
                alignItems: 'center',
                justifyContent: 'space-between',
                flexDirection: isMobile ? 'column' : 'row', 
            }}>

                <div style={{ display: 'flex', flexDirection: 'column', padding:20, maxWidth: 300 }}>
                    <span style={{ ...h1Style, color: 'white' }}>Supercharge your business with OdaNow!</span>
                    <span style={{ ...p1Style, color: 'white' }}>Our platform is designed to streamline and simplify the ordering process for businesses of all sizes.</span>
                    <div style={{ padding: 15 }}>
                        <Link style={{ padding: '10px 25px', color: '#ef5a29', background: 'white', border: 0, borderRadius: 20, textDecoration:'none', fontSize:14 }} to={'https://app.odanow.net'}>Let's Start</Link>
                        
                    </div>
                </div>
                <div style={{ display:'flex', justifyContent:'flex-end'}}>
                <img style={{width:isMobile ? '90%' : 400,}} src={'images/footer-banner-image.png'}/>
                </div>

            </div>


            {/* <SlideInAnimation>
           <div style={{background:'red', display:'inline-text', padding:'5px 15px', color:'white', boxShadow:'2px 2px 4px rgba(30,30,30,0.4)', width:'80%', height:400, borderRadius:5,}}>
            A
           </div>
           <div style={{background:'red', display:'inline-text', padding:'5px 15px', color:'white', boxShadow:'2px 2px 4px rgba(30,30,30,0.4)', width:'80%', height:400, borderRadius:5,}}>
            B
           </div>
           <div style={{background:'red', display:'inline-text', padding:'5px 15px', color:'white', boxShadow:'2px 2px 4px rgba(30,30,30,0.4)', width:'80%', height:400, borderRadius:5,}}>
            C
           </div>
           <div style={{background:'red', display:'inline-text', padding:'5px 15px', color:'white', boxShadow:'2px 2px 4px rgba(30,30,30,0.4)', width:'80%', height:400, borderRadius:5,}}>
            D
           </div>
           <div style={{background:'red', display:'inline-text', padding:'5px 15px', color:'white', boxShadow:'2px 2px 4px rgba(30,30,30,0.4)', width:'80%', height:400, borderRadius:5,}}>
            E
           </div>
        </SlideInAnimation> */}
        </div>
    )
}

export default LayoutWrapper(HomePage, { showNavigationBar: true, showSideMenu: true });